<!-- eslint-disable vue/no-template-key -->
<template>
  <!-- BEGIN: Content-->
  <div>
    <div
      v-if="!loading"
      id="offcanvasRetrieveApplicant"
      class="offcanvas offcanvas-end offcanvas-end--filters d-flex w-90"
      tabindex="-1"
      :class="{ show: visible }"
      :style="{ visibility: visible ? 'visible' : 'hidden' }"
      aria-labelledby="offcanvasRetrieveApplicantLabel"
    >
      <div class="offcanvas-header">
        <h3
          id="offcanvasSendStatusLabel"
          class="offcanvas-title"
        >
          <span class="btn btn-icon btn-icon rounded-circle btn-flat-secondary bg-light-secondary me-2"><i data-feather="send" /></span> Notify evaluators with pending evaluations
        </h3>
        <button
          type="button"
          class="btn-close text-reset btn btn-icon btn-icon rounded-circle btn-secondary"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          @click="closeOffcanva"
        />
      </div>
      <div class="offcanvas-body">
        <div class="row">
          <div class="col-sm-3">
            <h4>Evaluators list</h4>
            <div class="mb-1">
              <div class="form-check">
                <input
                  id="flexCheckDefault1"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  @click="hideAllModerators($event.target.checked)"
                >
                <label
                  class="form-check-label"
                  for="flexCheckDefault1"
                >Hide moderators</label>
              </div>
            </div>
          </div>
          <div class="col-sm-8">
            <h4>Filter areas</h4>
            <div class="mb-1">
              <div
                v-for="area in convo.areas"
                :key="area.id"
                class="form-check form-check-inline"
              >
                <input
                  id="flexCheckDefault1"
                  v-model="selectedAreas"
                  class="form-check-input"
                  type="checkbox"
                  :value="area.areaId"
                >
                <label
                  class="form-check-label"
                  for="flexCheckDefault1"
                >{{ area.code }}</label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <h5>Recipients</h5>
        <div
          class="btn-group mb-2"
          role="group"
          aria-label="select to"
        >
          <input
            id="btnradio1"
            type="radio"
            class="btn-check"
            name="btnradio"
            autocomplete="off"
          >
          <label
            class="btn btn-sm btn-outline-success"
            for="btnradio1"
            @click="recipients = filteredEvaluators"
          >Select all</label>

          <input
            id="btnradio2"
            type="radio"
            class="btn-check"
            name="btnradio"
            autocomplete="off"
            @click="recipients = []"
          >
          <label
            class="btn btn-sm btn-outline-success"
            for="btnradio2"
          >Deselect all</label>

          <input
            id="btnradio3"
            type="radio"
            class="btn-check"
            name="btnradio"
            autocomplete="off"
            @click="selectPendingEvaluators"
          >
          <label
            class="btn btn-sm btn-outline-success"
            for="btnradio3"
          >Select only with pending evaluations</label>
        </div>
        <div class="row">
          <div
            v-for="evaluator in filteredEvaluators"
            :key="'eval-' + evaluator.id"
            class="col-auto mb-1"
          >
            <div
              class="form-check"
            >
              <input
                id="flexCheckDefault2"
                class="form-check-input"
                type="checkbox"
                value=""
                :checked="recipients.includes(evaluator)"
                @click="$event.target.checked ? recipients.push(evaluator) : recipients = recipients.filter(e => e.id !== evaluator.id)"
              >
              <label
                class="form-check-label"
                for="flexCheckDefault2"
              >{{ evaluator.name }} <small
                v-if="evaluator.moderator"
                class="badge bg-light-secondary"
              >Moderator</small><br><SMALL>{{ evaluator.area ? evaluator.area.code : '-' }}</SMALL></label>
            </div>
          </div>
        </div>
        <hr>
        <label
          for=""
          class="form-label"
        >Content</label>
        <quill-editor v-model="content" />
      </div>
      <div class="offcanvas-footer mt-auto">
        <button
          type="button"
          class="btn btn-primary mb-1 d-grid"
          @click="notify"
        >
          Send
        </button>
      </div>
    </div>
    <div
      v-if="visible"
      class="offcanvas-backdrop fade"
      :class="{ show: visible }"
      @click="closeOffcanva"
    />
  </div>

  <!-- END: Content-->
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default {
  components: {
  },
  props: {
    round: { type: [Number, String], required: false, default: () => null },
  },
  data() {
    return {
      loading: false,
      content: '',
      hideModerators: false,
      recipients: [],
      selectedAreas: [],
    }
  },
  computed: {
    ...mapGetters({
      visible: 'modals/notifyEvaluators',
      convo: 'convo/convo',
      evaluators: 'convo/evaluators',
    }),
    filteredEvaluators() {
      const evals = this.evaluators.filter(e => {
        if (this.hideModerators && e.moderator) {
          return false
        }

        if (this.selectedAreas.length > 0) {
          return this.selectedAreas.includes(e.area.id)
        }

        return true
      })

      this.fixRecipients()

      return evals
    },
  },
  watch: {
    selected() {
      this.loading = true
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.loading = false
    },
  },
  methods: {
    fixRecipients() {
      this.recipients = this.recipients.filter(e => (this.selectedAreas.length > 0 ? this.selectedAreas.includes(e.area.id) : true))
    },
    closeOffcanva() {
      this.recipients = []
      this.content = ''
      this.$store.dispatch('modals/toggleNotifyEvaluators', false)
    },
    selectPendingEvaluators() {
      this.recipients = this.evaluators.filter(evaluator => {
        if (this.hideModerators && evaluator.moderator !== false) {
          return false
        }

        let applicants = 0

        if (this.round === 5) {
          applicants = evaluator.applicants.filter(e => e.pivot.competent == null).length
        }

        if (this.round === 6) {
          applicants = evaluator.applicants.filter(e => e.pivot.avg_grade === null).length
        }

        if (this.round === 7) {
          applicants = evaluator.applicants.filter(e => e.pivot.confirm === false).length
        }

        return applicants > 0
      })
    },
    notify() {
      Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/notify-pending-evaluators`, {
        recipients: this.recipients.map(e => e.id),
        convoId: this.convo.id,
        content: this.content,
      }).then(resp => {
        if (resp.status === 200) {
          Vue.swal('The recipients has been notified correctly!', '', 'success').then(() => {
            this.closeOffcanva()
          })
        } else {
          Vue.swal("The recipients haven't been notified due an error, Please contact the administrator.", '', 'error')
        }
      })
    },
    hideAllModerators(hide) {
      this.hideModerators = hide

      if (hide) {
        this.recipients = this.evaluators.filter(e => e.moderator === false && this.recipients.filter(f => f.id === e.id).length > 0)
      }
    },
  },
}
</script>
